/* $color-purple: #8B5CF6;
$color-pink: #EC4899;
$color-gray: #9CA3AF;
$color-black: #1F2937;
$card-size: 23rem; */

.storyContainer {
    /* background: linear-gradient(45deg, #2F2D02, #022F2C, #20022E, #2A011C); */
    overflow-x: hidden;
    position: relative;
    min-height: 100vh;
    /* background-size: 300% 300%; */
    animation: color 12s ease-in-out infinite;
}

@keyframes color {
  0%{
      background-position: 0 50%;
  }
  50%{
      background-position: 100% 50%;
  }
  100%{
      background-position: 0 50%;
  }
}

.storySlider {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.carousel {
  position: relative;
  width: 60vw;
  height: 80vh;
  perspective: 500px;
  transform-style: preserve-3d;
}

.card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: 
    rotateY(calc(var(--offset) * 50deg)) 
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
  transition: all 0.3s ease-out;
}

.card {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: repeat;
  border-radius: 1rem;
  color: #9CA3AF;
  transition: all 0.3s ease-out;
  position: relative;
}

.nav {
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 2;
  user-select: none;
  background: unset;
  border: unset;
  
  &.left {
    transform: translateX(-100%) translatey(-50%);
  }
  
  &.right {
    right: 0;
    transform: translateX(100%) translatey(-50%);
  }
}

/* Purple */
#gradient-canvas {
  width:100%;
  height:100%;
  --gradient-color-1: #820085; 
  --gradient-color-2: #b8009f; 
  --gradient-color-3: #ba66ff;  
  --gradient-color-4: #d3c7ff;
}

/* Black */
/* #gradient-canvas {
  width:100%;
  height:100%;
  --gradient-color-1: #000000; 
  --gradient-color-2: #292929; 
  --gradient-color-3: #454545;  
  --gradient-color-4: #737373;
} */

.storyDesignBlockContainer {
  position: relative;
  width: 300px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.storyDesignBlockContainer::after {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #D400D4);
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}

.storyDesignBlockContainer::before {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #00CCFF);
  animation: animate 4s linear infinite;
}

.storyDevBlockContainer {
  position: relative;
  width: 300px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.storyDevBlockContainer::after {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #D400D4);
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}

.storyDevBlockContainer::before {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #00CCFF);
  animation: animate 4s linear infinite;
}
.storyMarkBlockContainer {
  position: relative;
  width: 300px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.storyMarkBlockContainer::after {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #D400D4);
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}

.storyMarkBlockContainer::before {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #00CCFF);
  animation: animate 4s linear infinite;
}

.storyTestBlockContainer {
  position: relative;
  width: 300px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.storyTestBlockContainer::after {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #03825e);
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}

.storyTestBlockContainer::before {
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #c6f300);
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.storyDesignBlockContainer span {
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  background: rgb(84,96,107);
  background: radial-gradient(circle, rgba(84,96,107,1) 0%, rgba(50,58,66,1) 100%);
  z-index: 1;
}

.storyDevBlockContainer span {
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  background: rgb(84,96,107);
  background: radial-gradient(circle, rgba(84,96,107,1) 0%, rgba(50,58,66,1) 100%);
  z-index: 1;
}

.storyMarkBlockContainer span {
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  background: rgb(84,96,107);
  background: radial-gradient(circle, rgba(84,96,107,1) 0%, rgba(50,58,66,1) 100%);
  z-index: 1;
}

.storyTestBlockContainer span {
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  background: rgb(84,96,107);
  background: radial-gradient(circle, rgba(84,96,107,1) 0%, rgba(50,58,66,1) 100%);
  z-index: 1;
}

@media screen and (min-width: 1800px) {
  
}

@media screen and (max-width: 1100px) {
  
}