.PhoneInput input {
    width: 150px;
    height: 40px;
    border: 1px solid #D8D9D9;
    padding: 4px 8px;
    border-radius: 2px;
}

.PhoneInputCountryIcon {
    width: 32px;
    height: 24px;
}

.PhoneInputCountrySelectArrow {
    border-color: white;
}

@media screen and (max-width: 650px) {
    .PhoneInput input {
        width: 100%;
    }
}