.rotateAnim {
    -webkit-animation:spin 12s linear infinite;
    -moz-animation:spin 12s linear infinite;
    animation:spin 12s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* .cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    transition: 0.1s;
    transform: translate(-50%,-50%);
    pointer-events: none;
    mix-blend-mode: difference;
    z-index: 999;
} */