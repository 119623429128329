.animGradBg {
    height: 100dvh;
    width: 100%;
    background: linear-gradient(45deg,#6C00C1, #C1005C, #02A184,#EAE100);
    background-size: 300% 300%;
    animation: color 12s ease-in-out infinite;
}

@keyframes color {
    0%{
        background-position: 0 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}

.blockContainer {
    display: grid;
    grid-template-columns: repeat(4,150px);
    grid-gap: 10px;
}

.blockContainer .block {
    position: relative;
    width: 150px;
    height: 150px;
    background: rgba(45, 45, 45, 1);
    overflow: hidden;
}

.blockContainer .block::before {
    content: '';
    position: absolute;
    /* top: var(--y);
    left: var(--x);
    transform: translate(50%, 50%); */
    background: radial-gradient(var(--clr), transparent, transparent);
    width: 300px;
    height: 300px;
    opacity: 0;
    transition: 0.5s;
    /* transition: 0.5s, top 0s, left 0s; */
}

.blockContainer .block:hover:before {
    opacity: 1;
}

.blockContainer .block::after {
    content: '';
    position: absolute;
    inset: 2px;
    background: rgba(45, 45, 45, 0.9);
}