.slideTrack {
    overflow: hidden;
    display: flex;
    position: relative;
}

/* .slideTrack:before,
.slideTrack:after {
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
    content: "";
    z-index: 2;
}

.slideTrack:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.slideTrack:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
} */

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.slide {
    display: flex;
    animation: 40s slide infinite linear;
    padding-bottom: 80px;
}

.slideTrack:hover .slide {
    animation-play-state: paused;
}

